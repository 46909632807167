import React from 'react'
import siteUrl from '../../../utils/siteUrl'
import logo404 from "../../../assets/images/404.svg"
import LogoBlanco from "../../../assets/images/logo-blanco.svg"
import Img1 from "../../../assets/images/img1.svg"
import Img2 from "../../../assets/images/img2.svg"
import "./page-not-found.scss"


const PageNotFound = () => {
    return (
        <>
            <header className="f-page-not-found-header">
                <img src={LogoBlanco} />
            </header>

            <main>
                <div className="f-page-not-found-banner">
                    <img src={Img1} className="f-page-not-found-banner__img-lateral"/>
                    <img src={logo404} className="f-page-not-found-banner__img-center" />
                    <img src={Img2}className="f-page-not-found-banner__img-lateral" />
                </div>
                <div className="f-page-not-found-cta">
                    <h1 className="f-page-not-found-cta__title">
                        ¡Lo sentimos!
                    </h1>
                    <p className="f-page-not-found-cta__description">
                        <span>
                            La página que intentas ver no se encuentra disponible.
         
                            </span>
                        <br />
                    Aquí encontrarás algunos enlaces útiles en su lugar:
                    </p>

                    <div className="f-page-not-found-cta__actions-wrapper">
                        <a className="f-page-not-found-cta__action" href={`${siteUrl}`} >
                            Home
                        </a>
                        <a className="f-page-not-found-cta__action" href={`${siteUrl}/buscador`} >
                            Buscador
                        </a>
                        <a className="f-page-not-found-cta__action" href={`${siteUrl}/contactanos`} >
                            Contáctanos
                        </a>
                    </div>
                </div>
            </main>
        </>
    )
}

export default PageNotFound
