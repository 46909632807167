import React from "react"
import SEO from "../shared/components/atoms/seo/seo"
import PageNotFound from "../shared/components/organisms/page-not-found/page-not-found"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <PageNotFound />
  </>
)

export default NotFoundPage
